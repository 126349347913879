import * as React from "react"

import Navigation from "../components/navigation"
import Footer from "../components/footer"

const PageLayout = ({ location, title, children }) => {
	const rootPath = `${__PATH_PREFIX__}/`
	const isRootPath = location.pathname === rootPath

	return (
		<div data-is-root-path={isRootPath} className="bg-gray-50">
			<Navigation />
			<main>{children}</main>
			<Footer />
		</div>
	)
}

export default PageLayout
